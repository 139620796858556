<template>
  <div
    class="flex items-center text-xs font-normal leading-normal text-red-600 min-h-6"
  >
    <slot>{{ message }}</slot>
  </div>
</template>
<script>
export default {
  name: 'AppInputError',
  props: {
    message: {
      required: false,
    },
  },
}
</script>

<style scoped>
.min-h-6 {
  min-height: 1.5rem;
}
</style>
